import Vue from 'vue'
import index from '@/api/index'
export default ({ $axios, redirect, store, error }, inject) => {
  // 基本配置
  $axios.defaults.timeout = 40000
  // 配置基础接口域名
  // $axios.defaults.baseURL = process.env.BASE_URL
  // console.log($axios)
  // $axios.defaults.baseURL = '/api'
  // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  // $axios.defaults.headers = {'Content-Type': 'application/json;charset=UTF-8'}
  //  $axios.defaults.transformRequest = [function (data) {
  //   // 将参数对象转成JSON形式
  //   data = JSON.stringify(data)
  //   return data
  // }]
  // 请求拦截器
  $axios.onRequest(config => {
    // console.log(config, 'configconfig')
    // store.commit('system/toggleLoading', true)
    // console.log(store.state.system.userToken)
    if (store.state.system.userToken) {
      config.headers.Authorization = 'bearer ' + store.state.system.userToken
    }
    return config
  })

  // 响应拦截器
  $axios.onResponse(res => {
    if (res.status === 200) {
      //       if (res.data.code === 3) {

      //       } else if (res.data.code === 3) {
      // Vue.prototype.$tips({ msg: 'Login status has expired' })
      //         store.commit('system/logOut')
      //       }
      // Vue.prototype.$loading()
      // if (process.client) {
      //   Vue.prototype.$loading().close()
      // }
      // if (res.config.url === 'https://api.ipify.org?format=json') {
      if (res.config.url === 'https://ipapi.co/ip') {
        return Promise.resolve(res.data)
      }
      if (res.data.code === 3) {
        Vue.prototype.$tips({ msg: 'Login status has expired.' })
        store.commit('system/logOut')
      } else if (res.data.code !== 200) {
        Vue.prototype.$tips({ msg: res.data.msg })
      }
      return Promise.resolve(res.data)
    }
  })
  $axios.onError(errorRes => {
    // if (process.client) {
    //   Vue.prototype.$loading().close()
    // }
    // error
    // console.log('<Interface error>', error.response.status)
    // ctx.error({statusCode: 500, message: '服务器开小差了~' })
    // Vue.prototype.$tips({ msg: '' })
    // console.log('<Interface error>', error)
    // console.log('response-error,code:', error.response.status)
    // const code = parseInt(error.response && error.response.status)
    // if (code === 404 || code === 500) {
    // //   redirect('/error')
    //   console.log('Interface error', error)
    // }+
    // redirect('/')
    // redirect('/')
    // redirect({ name: 'shoppingCart' })
    // return Promise.resolve(error.response)
    // return Promise.reject(error.response)
    const code = parseInt(errorRes.response && errorRes.response.status)
    if (code === 404 || code === 500 || code === 504) {
      // redirect('/error')
      error({ statusCode: code })
    }

    return Promise.reject(error)
  })
  inject('api', index($axios))
}
