// 防抖
export function debounce(fn, wait) {
  let timeout = null
  wait = wait || 600
  return function () {
    const that = this
    if (timeout !== null) { clearTimeout(timeout) }
    timeout = setTimeout(() => {
      fn.apply(that)
    }, wait)
  }
}
export function debounceBtn(fn, delay, immediate = false) {
  let timer = null
  return function(...args) {
    if (timer) { clearTimeout(timer) }
    if (immediate && !timer) {
      fn.apply(this, args)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}
// 随机数 + 时间戳
export function getRandomString(len, noTime) {
  const _charStr = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789'
  const time = Date.now()
  const min = 0
  const max = _charStr.length - 1
  let _str = '' // 定义随机字符串 变量
  len = len || 15 // 判断是否指定长度，否则默认长度为15
  // 循环生成字符串
  for (let i = 0, index; i < len; i++) {
    index = (function(randomIndexFunc, i) {
      return randomIndexFunc(min, max, i, randomIndexFunc)
    })(function(min, max, i, _self) {
      let indexTemp = Math.floor(Math.random() * (max - min + 1) + min)
      const numStart = _charStr.length - 10
      if (i === 0 && indexTemp >= numStart) {
        indexTemp = _self(min, max, i, _self)
      }
      return indexTemp
    }, i)
    _str += _charStr[index]
  }
  if (!noTime) {
    return _str + '-' + time
  } else {
    return _str
  }
}
// 生成num小时时间戳(毫秒)
export function setHoursTimeNum(num) {
  return 3600 * 1000 * num
}
// 生成num小时时间戳(秒)
export function setHoursTimeNumS(num) {
  return num * 60 * 60
}
// // 将日期字符串转换为时间戳(05/21/2023)
export function getTimestampFromDateString(dateString) {
  const [month, day, year] = dateString.split('/')
  const dateObject = new Date(`${month}/${day}/${year}`)
  const timestamp = dateObject.getTime()
  return timestamp
}
// 页面加载事件
// function contentLoaded(win, fn) {
//   let done = false
//   let top = true
//   const doc = win.document
//   const root = doc.documentElement
//   const modern = doc.addEventListener
//   const add = modern ? 'addEventListener' : 'attachEvent'
//   const rem = modern ? 'removeEventListener' : 'detachEvent'
//   const pre = modern ? '' : 'on'
//   const init = function (e) {
//     if (e.type === 'readystatechange' && doc.readyState !== 'complete') {
//       return
//     }
//     ;(e.type === 'load' ? win : doc)[rem](pre + e.type, init, false)
//     if (!done && (done = true)) {
//       fn.call(win, e.type || e)
//     }
//   }
//   const poll = function () {
//     try {
//       root.doScroll('left')
//     } catch (e) {
//       setTimeout(poll, 50)
//       return
//     }
//     init('poll')
//   }

//   if (doc.readyState === 'complete') {
//     fn.call(win, 'lazy')
//   } else {
//     if (!modern && root.doScroll) {
//       try {
//         top = !win.frameElement
//       } catch (e) {}
//       if (top) {
//         poll()
//       }
//     }
//     doc[add](pre + 'DOMContentLoaded', init, false)
//     doc[add](pre + 'readystatechange', init, false)
//     win[add](pre + 'load', init, false)
//   }
// }
