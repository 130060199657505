const res = require.context('@/api/module', false, /\.js$/)

export default ($axios) => {
  const apiModule = {}
  res.keys().forEach((path) => {
    const context = res(path).default || res(path)
    // 此处 context 即为每个 .js 文件的导出内容
    const name = path.split('.js')[0].split('/')[1]
    // 创建子模块
    apiModule[name] = {}
    // 填充子模块api
    for (const key in context($axios)) {
      apiModule[name][key] = context($axios)[key]
    }
  })

  return apiModule
}
