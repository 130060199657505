export default $axios => ({
  // 信息------------------------------------------------------------
  // 用户信息
  userInfo(header = {}) {
    return $axios({
      url: '/api/user/userInfo',
      method: 'post',
      headers: header
    })
  },
  setUserInfo(data, header = {}) {
    return $axios({
      url: '/api/user/setUserInfo',
      method: 'post',
      data,
      headers: header
    })
  },
  // 密码------------------------------------------------------------
  // 设置密码
  setPassword(data, header = {}) {
    return $axios({
      url: '/api/user/setPassword',
      method: 'post',
      data,
      headers: header
    })
  },
  // 地址------------------------------------------------------------
  // 获取地址列表
  getAddressList(header = {}) {
    return $axios({
      url: '/api/address/list',
      method: 'post',
      headers: header
    })
  },
  // 获取地址详情
  getAddressInfo(data, header = {}) {
    return $axios({
      url: '/api/address/info',
      method: 'post',
      data,
      headers: header
    })
  },
  // 保存地址信息
  saveAddress(data, header = {}) {
    return $axios({
      url: '/api/address/save',
      method: 'post',
      data,
      headers: header
    })
  },
  // 删除地址信息
  removeAddress(data, header = {}) {
    return $axios({
      url: '/api/address/delete',
      method: 'post',
      data,
      headers: header
    })
  },
  // 获取国家列表
  getCountryList() {
    return $axios({
      url: '/api/other/CountryList',
      method: 'get'
    })
  },
  // 获取国家手机区号
  getCountryCode() {
    return $axios({
      url: '/api/other/phoneCode',
      method: 'get'
    })
  },
  // 获取省列表
  getProvinceList(data, headers = {}) {
    return $axios({
      url: '/api//other/provinceList',
      method: 'post',
      data,
      headers
    })
  },
  // 收藏------------------------------------------------------------
  // 用户收藏
  wishlist(data, headers = {}) {
    return $axios({
      url: '/api/wishlist/list',
      method: 'post',
      data,
      headers
    })
  },
  // 订阅------------------------------------------------------------
  // 用户订阅
  getSubscription(data, headers) {
    return $axios({
      url: '/api/subscription/list',
      method: 'post',
      headers
    })
  },
  saveSubscription(data, headers = {}) {
    return $axios({
      url: '/api/subscription/save',
      method: 'post',
      data,
      headers
    })
  },
  // 邮箱订阅
  subscriptionE(data, headers = {}) {
    return $axios({
      url: '/api/user/subscribe',
      method: 'post',
      data,
      headers
    })
  },
  // 处方------------------------------------------------------------
  // 处方列表
  prescriptionList(headers = {}) {
    return $axios({
      url: '/api/prescription/list',
      method: 'post',
      headers
    })
  },
  // 处方信息
  prescriptionDetiles(data, headers = {}) {
    return $axios({
      url: '/api/prescription/info',
      method: 'post',
      data,
      headers
    })
  },
  // 处方保存
  prescriptionSave(data, headers = {}) {
    return $axios({
      url: '/api/prescription/save',
      method: 'post',
      data,
      headers
    })
  },
  // 处方删除
  prescriptionDelete(data, headers = {}) {
    return $axios({
      url: '/api/prescription/delete',
      method: 'post',
      data,
      headers
    })
  },
  // 订单-------------------------------
  getOrderList(data, headers = {}) {
    return $axios({
      url: '/api/order/list',
      method: 'post',
      data,
      headers
    })
  },
  // 订单详情
  getOrderInfo(data, headers = {}) {
    return $axios({
      url: '/api/order/info',
      method: 'post',
      data,
      headers
    })
  },
  // 订单删除
  getOrderDel(data, headers = {}) {
    return $axios({
      url: '/api/order/delete',
      method: 'post',
      data,
      headers
    })
  },
  // 商品评论
  productReview(data, headers = {}) {
    return $axios({
      url: '/api/review/save',
      method: 'post',
      data,
      headers
    })
  },
  productReviewInfo(data, headers = {}) {
    return $axios({
      url: '/api/review/info',
      method: 'post',
      data,
      headers
    })
  },
  // 投诉建议---------------------------------------
  // 反馈列表
  contactlist(data, headers = {}) {
    return $axios({
      url: '/api/contact/list',
      method: 'post',
      data,
      headers
    })
  },
  contactSave(data, headers = {}) {
    return $axios({
      url: '/api/contact/save',
      method: 'post',
      data,
      headers
    })
  },
  // 结算优惠券---------------------------------------
  getCouponList(data, headers = {}) {
    return $axios({
      url: '/api/coupon/my',
      method: 'post',
      data,
      headers
    })
  },
  getCenterCouponList(data, headers = {}) {
    return $axios({
      url: '/api/coupon/list',
      method: 'post',
      data,
      headers
    })
  }
})
