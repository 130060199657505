import CryptoJS from 'crypto-js'
// 16位的密钥，自己定义，和下面的密钥要相同
const keyText = 'stylool666admin0'
/**
 * @encription: 加密
 * @param {*} word 需要加密的内容
 * @param {*} keyStr 自定义秘钥
 */
export const setPassword = (word, keyStr) => {
  const key = CryptoJS.enc.Utf8.parse(keyText) //  字符串到数组转换，解析秘钥
  const srcs = CryptoJS.enc.Utf8.parse(word) //  字符串到数组转换，解析明文
  // mode:加密方式；padding:填充方式；iv便宜向量（可选）
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString() // 加密后的结果是对象，要转换为文本
}
/**
 * @description: 解密
 * @param {*} word 需要解密的内容
 * @param {*} keyStr 自定义秘钥 需要和加密时使用的一致
 */
export const getPassword = (word, keyStr) => {
  const key = CryptoJS.enc.Utf8.parse(keyText) //  字符串到数组转换
  const decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Utf8.stringify(decrypt).toString() //  数组到字符串转
}
