const Cookies = process.client ? require('js-cookie') : undefined

export function getToken(TokenKey) {
  return Cookies ? Cookies.get(TokenKey) : ''
}

export function setToken (TokenKey, token) {
  return Cookies && Cookies.set(TokenKey, token)
}

export function removeToken (TokenKey) {
  return Cookies && Cookies.remove(TokenKey)
}

export function setLocalStorage(key, value) {
  if (!process.client) { return false }
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  window.localStorage.setItem(key, value)
}
export function getLocalStorage(key) {
  if (!process.client) { return false }
  const data = window.localStorage.getItem(key)
  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}
export function removeLocalStorage(key) {
  if (!process.client) { return false }
  window.localStorage.removeItem(key)
}
// 解析cookie中的参数
export function cookieParse (cookie) {
  if (!cookie) { return {} }
  const cookies = {}
  cookie.split(';').forEach(item => {
    const parts = item.split('=')
    cookies[parts[0].trim()] = parts[1].trim()
  })
  return cookies
}
