import Vue from 'vue'
import myCom from '@/components/global/remind.vue'
const tips = {}
tips.install = function(Vue) {
  const Mydialog = Vue.extend(myCom)
  // 生成一个该子类的实例
  const instance = new Mydialog()
  /**
   *
   * @param {object} config
   * msg: string 提示信息
   * time: string 停留时间
   */
  Vue.prototype.$tips = (config) => {
    // 这里 return 一个 Promise
    // 在项目中使用的时候，就可以进行链式调用了
    return new Promise((resolve, reject) => {
      if (config) {
        // console.log(config)
        instance.config = Object.assign({}, instance.config, config)
        instance.show = true
        resolve()
      }
    })
  }
  // 将这个实例挂载在我创建的div上
  // 并将此div加入全局挂载点内部
  instance.$mount(document.createElement('div'))
  document.body.appendChild(instance.$el)
}
Vue.use(tips)
