/**
 *   未知路由重定向 首页
 */
export default ({ store, route, redirect, error }) => {
  // if (!route.name) {
  //   console.log('🚀 ~ f======================', route.name === null)

  //   error({ statusCode: 404 })
  //   // return false
  // }
  if (!route.matched.length) { // 无匹配路由
    // const page = route.name
    // console.log(route)
    // debugger
    error({ statusCode: 404 })
    // redirect('/')
  } else { // 无权限路由
    const token = store.state.system.userToken
    const page = route.name.split('-')[0]
    const authorityPage = ['centerMain']
    if (!token && authorityPage.includes(page)) {
      redirect('/')
    }
  }
}
