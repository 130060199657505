import { getLocalStorage } from '@/assets/utils/storage'
const key = 'LOACL_DD'
export default $axios => ({
  // 获取货币列表
  getCountry(data, header = {}) {
    return $axios({
      url: '/api/other/country',
      method: 'get',
      headers: header
    })
  },
  // 获取商品属性
  getGoodsType(data, header = {}) {
    return $axios({
      url: '/api/goods/attribute',
      method: 'post',
      headers: header
    })
  },
  // 登录
  toLogin(data, headers = {}) {
    headers.localdd = getLocalStorage(key)
    return $axios({
      url: '/api/user/login',
      method: 'post',
      data,
      headers
    })
  },
  // 一键注册
  regGetCoupon(data, headers = {}) {
    return $axios({
      url: '/api/user/fastRegister',
      method: 'post',
      data,
      headers
    })
  },
  // 发送邮箱验证码
  getCodePassWord(data, headers = {}) {
    return $axios({
      url: '/api/user/forgetPasswordEmail',
      method: 'post',
      data,
      headers
    })
  },
  // 忘记密码 设置
  setPassword(data, headers = {}) {
    return $axios({
      url: '/api/user/forgetPassword',
      method: 'post',
      data,
      headers
    })
  },
  toRegister(data, header = {}) {
    return $axios({
      url: '/api/user/register',
      method: 'post',
      data,
      headers: header
    })
  },
  // nav-------------------------------------------------
  // nav  获取收藏数量
  getWishlistCount(data, headers = {}) {
    return $axios({
      url: '/api/wishlist/count',
      method: 'post',
      data,
      headers
    })
  },
  // nav  获取购物车数量
  getCartCount(data, headers = {}) {
    headers.localdd = getLocalStorage(key)
    return $axios({
      url: '/api/cart/count',
      method: 'post',
      data,
      headers
    })
  },
  // 人脸识别 上传
  faceCheckUp(data, headers = {}) {
    // headers.localdd = getLocalStorage(key)
    return $axios({
      url: '/api/detectface/upload',
      method: 'post',
      data,
      headers
    })
  },
  getModel(data, headers = {}) {
    // headers.localdd = getLocalStorage(key)
    return $axios({
      url: '/api/detectface/list',
      method: 'post',
      data,
      headers
    })
  },
  // 删除模特
  delModel(data, headers = {}) {
    // headers.localdd = getLocalStorage(key)
    return $axios({
      url: '/api/detectface/delete',
      method: 'post',
      data,
      headers
    })
  },
  // 页面记录
  track_log(data, headers = {}) {
    return $axios({
      url: '/api/other/statistics',
      method: 'post',
      data,
      headers
    })
  },
  // 获取ip
  getIpData() {
    return $axios({
      // url: 'https://api.ipify.org?format=json',
      url: 'https://ipapi.co/ip',
      method: 'get'
    })
  }
})
