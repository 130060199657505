// import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
// import system from './system'
// import productConfig from './productConfig'
// export default new Vuex.Store({
//   modules: {
//     system, productConfig
//   },
//   plugins: [createPersistedState()]
// })
export const state = () => {

}
export const getters = {}
export const mutations = {}
export const actions = {
  // 初始化store服务端要的一些数据
  nuxtServerInit({ commit, state }, { req }) {
    // 服务端解析cookie
    const cookies = req.headers.cookie ? req.headers.cookie.split('; ') : []
    cookies.some((item) => {
      const arr = item.split('=')
      if (arr[0] === 'vuex') {
        // 处理cookie中的内容
        const cookie = JSON.parse(decodeURIComponent(arr[1]))
        for (const key in cookie) {
          // state[key] =cookie[key]
          state[key] = Object.assign(state[key], cookie[key])
        }
      }
      return false
    })
  }
}
