import Vue from 'vue'
import myConfirm from '@/components/global/confirm.vue'
const confirm = {}
function setBody(type) {
  // 页面滚动
  // true 允许
  const body = document.body

  if (type) {
    body.style.overflow = 'unset'
    body.style.height = 'auto'
  } else {
    body.style.overflow = 'hidden'
    body.style.height = '100vh'
  }
}
confirm.install = function(Vue) {
  const Mydialog = Vue.extend(myConfirm)
  // 生成一个该子类的实例
  const instance = new Mydialog()
  Vue.prototype.$confirm = (config) => {
    // 这里 return 一个 Promise
    // 在项目中使用的时候，就可以进行链式调用了
    return new Promise((resolve, reject) => {
      if (config) {
        setBody(false)
        // console.log(config)
        instance.config = Object.assign({}, instance.config, config)
        instance.show = true
        instance.cancel = () => {
          setBody(true)
          instance.show = false
          resolve('cancel')
        }
        instance.confirm = () => {
          setBody(true)
          instance.show = false
          resolve('success')
        }
      }
    })
  }
  // 将这个实例挂载在我创建的div上
  // 并将此div加入全局挂载点内部

  instance.$mount(document.createElement('div'))
  document.body.appendChild(instance.$el)
}
Vue.use(confirm)
