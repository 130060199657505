// 全局变量注册
import Vue from 'vue'
// const BASE_URL = process.env.BASE_URL
const global = {
  URL: process.env.BASE_URL, // 动态路径
  IMGPATH: 'https://www.stylool.com',
  PROD_PAYPAL: 'https://www.paypal.com/sdk/js?client-id=Aa_b3bgNz5zZqfHw2zefTKNAkQo9v9N24D734A50MhudnSErvT9aHwVYujvryLoxe0G4WLo621ygjz7W&commit=true&currency=USD',
  DEV_PAYPAL: 'https://www.paypal.com/sdk/js?client-id=ASMo5HatkXqEbOdSs4A3n-AL3Xw1tTwWU4dQR6Arj_OUdRl8XvQRmhFvZWG_PI99rBQsVromNJ2hU0Ly&commit=true&currency=USD',
  SRTRIPE_public_key_dev: 'pk_test_51Mqru4DdEs6DfGnd5oMQziVUYLmZCwNArrQ5bD4Bfzrzuw1PVPHvEg5RgaGMBOVM9EtdHwojCUttOSdA96CbBcvX00qjLvLHcT',
  SRTRIPE_public_key_pro: 'pk_live_51Mqru4DdEs6DfGndWLxwmBqZPqwusdcfkikFQw1QBqqSBS8YtFab2GxfABTeR6W8wTxjodamBJmm3yeMRKsPZJM900IWXwuwUO',
  SRTRIPE_LINK: 'https://js.stripe.com/v3'
}
Vue.prototype.GLOBAL = global
