import { getLocalStorage } from '@/assets/utils/storage'
const key = 'LOACL_DD'
export default $axios => ({
  // 获取商品列表
  getGoodsList(data, header = {}) {
    return $axios({
      url: '/api/goods/list',
      method: 'post',
      data,
      header
    })
  },
  // 详情页--------------------------------------------
  // 商品详情
  getProductInfo(data, header = {}) {
    return $axios({
      url: '/api/goods/info',
      method: 'post',
      headers: header,
      data
    })
  },
  // 商品设置收藏
  setWishlist(data, header = {}) {
    return $axios({
      url: '/api/wishlist/is',
      method: 'post',
      data,
      headers: header
    })
  },
  // 获取评论
  getReviews(data, header = {}) {
    return $axios({
      url: '/api/review/list',
      method: 'post',
      data,
      headers: header
    })
  },
  // 流程页--------------------------------------------
  // 商品信息
  flowStart(data, header = {}) {
    return $axios({
      url: '/api/process/product',
      method: 'post',
      data,
      headers: header
    })
  },
  // 上传流程图片
  FlowUploadImg(data, header = {}) {
    return $axios({
      url: '/api/process/upload',
      method: 'post',
      data,
      headers: header
    })
  },
  // 提交/获取流程信息
  FlowNext(data, header = {}) {
    return $axios({
      url: '/api/process/next',
      method: 'post',
      data,
      headers: header
    })
  },
  FlowSubmit(data, headers = {}) {
    headers.localdd = getLocalStorage(key)
    return $axios({
      url: '/api/process/submit',
      method: 'post',
      data,
      headers
    })
  }
})
