import { add } from '@/assets/utils/decimal'

// import Vue from 'vue'
// Vue.prototype.$tips({ msg: 'Please select the required configuration.' })
// 眼镜用途
const lensesUse = () => import('@/components/Lproduct/config/lensesUse')
// 眼镜处方
const prescription = () => import('@/components/Lproduct/config/prescription')
// 放大 reader
const lensReaders = () => import('@/components/Lproduct/config/lensReaders')
// 眼镜附加服务(涂层)
const addOnes = () => import('@/components/Lproduct/config/addOnes.vue')
// 眼镜折射率
const lensIndex = () => import('@/components/Lproduct/config/lensIndex')
// 眼镜类型
const lensesType = () => import('@/components/Lproduct/config/lensesType')
// const selsctLenses = ['Next', 'Submit Prescription', 'Next', 'Next']
// ---- 初次进入第一步时  需要重置数据!!!!!!!!!!!!!!!
const state = () => ({
  loading: false, // 步骤切换loading
  step: { // 步骤控制器
    step_id: undefined, // 本次流程id
    size: 3, // 总步骤  (控制器显示)
    currentStep: 1, // 当前步骤 (控制器显示)
    name: undefined, // 步骤组件名称 (判断填充数据)
    glassesType: undefined, // 眼镜类型
    // title: undefined, // 步骤名称
    // is_end: 0, // 是否为最后一步
    nextControl: true, // 是否禁用下一步
    flowList: [] // 经过步骤
  },
  footData: {},
  selectData: [], // 流程选配置
  lensesUseData: {}, // 用途数据
  addOnesData: {}, // 涂层数据
  lensIndexData: {}, // 折射率数据
  lensesTypeData: {}, // 类型数据
  prescriptionData: {}, // 处方限制
  RXShowData: {}, // 编辑回显处方数据
  renderData: {} // 老花镜限制
})
const flowName = (type) => {
  if (!type) { return '' }
  let name
  switch (type) {
    case 'lens_use':
      name = 'prescription'
      break
    case 'prescription':
      name = 'config'
      break
    case 'magnification':
      name = 'lens'
      break
    case 'add_ones':
      name = 'lens'
      break
    case 'lens_index':
      name = 'lens'
      break
    case 'lens_type':
      name = 'lens'
      break
    default:
      name = 'lens'
      break
  }
  return name
}
const getters = {
  // 流程按钮名称
  btnName(state, getter) {
    if (!state.step.flowList.length) { return 'Next' }
    if (state.step.flowList[state.step.flowList.length - 1].name === 'prescription') {
      return 'Submit Prescription'
    }
    // 最后一步
    if (state.step.currentStep === state.step.size) {
      return 'Add to Cart'
    } else {
      return 'Next'
    }
  },
  // 设置流程组件
  setComName(state) {
    let setpName = ''

    if (state.step.flowList.length) {
      setpName = state.step.flowList[state.step.flowList.length - 1].name
    }
    let comName
    switch (setpName) {
      case 'lens_use':
        comName = lensesUse
        break
      case 'prescription':
        comName = prescription
        break
      case 'magnification':
        comName = lensReaders
        break
      case 'add_ones':
        comName = addOnes
        break
      case 'lens_index':
        comName = lensIndex
        break
      case 'lens_type':
        comName = lensesType
        break
      default:
        comName = null
        break
    }
    return comName
  },
  // 页脚价目清单  (处方不显示)
  getInventory(state) {
    const data = state.selectData
    // 清单
    const lens = []
    const prescription = []
    // 总价
    let total = state.footData.price
    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      if (element.type !== 'prescription') {
        total = add(total, element.price)
        const item = {
          name: flowName(element.type), // 归类
          price: element.price,
          item: element.item // 选项
        }
        if (flowName(element.type) === 'lens') {
          lens.push(item)
        } else if (flowName(element.type) === 'prescription') {
          prescription.push(item)
        }
      }
    }
    // 获取 镜框/镜片/用途/总价
    return {
      frame: {
        title: state.footData.title,
        price: state.footData.price
      },
      lens,
      prescription,
      total
    }
  }
}
const mutations = {
  // 编辑---------------------------------------------------------------
  // 插入第一个步骤
  insetFirstFlowList(state, payload) {
    if (state.step.flowList[0].name !== 'lens_use') {
      state.step.flowList.unshift(payload)
    }
  },
  // 暂存处方数据 回显
  StagingRX(state, payload) {
    state.RXShowData = payload
  },
  // ---------------------------------------------------------------
  // 前进 经过步骤
  setFlowList(state, payload) {
    state.step.flowList.push(payload)
  },
  // 回退 设置步骤
  reSetFlowList(state) {
    // 标记要删除的步骤名称
    const delType = state.step.flowList[state.step.flowList.length - 1].name
    // 删除
    state.step.flowList.pop()
    // 删除已经选数据
    const selectData = state.selectData
    for (let index = 0; index < selectData.length; index++) {
      const element = selectData[index]
      if (element.type === delType) {
        selectData.splice(index, 1)
      }
    }
    // 设置当前步骤名
    state.step.name = state.step.flowList[state.step.flowList.length - 1].name
    // 解除禁用按钮
    this.commit('productConfig/setFootBtn', false)
  },
  // 设置步骤数量  (更新步骤数量)
  setStepNum(state, payload) {
    state.step.size = payload
  },
  // 设置当前步骤序号
  setCurrentStep(state, payload) {
    state.step.currentStep = payload
  },
  // 重置vuex数据
  resetData(state) {
    const initData = {
      loading: false, // 步骤切换loading
      step: { // 步骤控制器
        step_id: undefined,
        size: 3, // 总步骤
        currentStep: 1, // 当前步骤
        name: undefined, // 步骤组件名称
        glassesType: undefined,
        // title: undefined, // 步骤名称
        // is_end: 0, // 是否为最后一步
        nextControl: true, // 是否禁用下一步
        flowList: [] // 经过步骤
      },
      footData: {},
      selectData: [], // 流程选配置
      lensesUseData: {}, // 用途数据
      addOnesData: {}, // 涂层数据
      lensIndexData: {}, // 折射率数据
      lensesTypeData: {}, // 类型数据
      prescriptionData: {}, // 处方限制
      RXShowData: {},
      renderData: {} // 老花镜限制
    }
    Object.assign(state, initData)
  },
  // 设置页脚按钮状态
  setFootBtn(state, payload) {
    state.step.nextControl = payload
  },
  // 设置页脚信息
  setFootData(state, payload) {
    state.footData = payload
  },
  // 设置 步骤数量/名称/组件名
  controlStepData(state, payload) {
    state.step.size = payload.size
    state.step.name = payload.name
    state.step.glassesType = payload.glassesType
    state.step.step_id = payload.step_id
    // state.step.title = payload.title
    // state.step.is_end = payload.is_end
  },
  // 设置对应流程数据
  setFlowData(state, payload) {
    const name = state.step.name
    let dataName
    switch (name) {
      case 'lens_use':
        dataName = 'lensesUseData'
        break
      case 'prescription':
        dataName = 'prescriptionData'
        break
      case 'magnification':
        dataName = 'renderData'
        break
      case 'add_ones':
        dataName = 'addOnesData'
        break
      case 'lens_index':
        dataName = 'lensIndexData'
        break
      case 'lens_type':
        dataName = 'lensesTypeData'
        break
      default:
        dataName = 'lensesUseData'
        break
    }
    state[dataName] = payload
  },
  // 步骤切换loading
  setLoadingState(state, payload) {
    state.laoding = payload
  },
  // 步骤控制器  点击footer 按钮
  controlStep(state, payload) {
    const step = state.step
    if (payload === 'next' & step.currentStep < step.size) {
      step.currentStep++
    }
    if (payload === 'pre' & step.currentStep > 1) {
      this.commit('productConfig/reSetFlowList')
      step.currentStep--
    }
  },
  // 错误  步数回退
  backControlStep(state) {
    if (state.step.currentStep !== 1) {
      state.step.currentStep--
    }
  },
  // 设置已经选择的数据
  setSelectData(state, payload) {
    const data = state.selectData
    const index = data.findIndex((value) => {
      return value.type === payload.type
    })
    if (index > -1) {
      state.selectData[index].type = payload.type
      state.selectData[index].value = payload.data.value
      state.selectData[index].item = payload.data.name ? payload.data.name : ''
      state.selectData[index].price = payload.data.money ? payload.data.money : 0
    } else {
      state.selectData.push({
        type: payload.type,
        value: payload.data.value,
        price: payload.data.money ? payload.data.money : 0,
        item: payload.data.name ? payload.data.name : ''
      })
    }
    state.step.nextControl = false
  }
}
const actions = {
  // 验证处方数据
  validatePrescription({ commit, state }, payload) {
    const rightNum = {
      sph: getNum(payload.od_sph),
      cyl: getNum(payload.od_cyl)
    }
    const leftNum = {
      sph: getNum(payload.os_sph),
      cyl: getNum(payload.os_cyl)
    }
    return new Promise((resolve, reject) => {
      if (getAbs(rightNum.sph - leftNum.sph) >= 3 || getAbs(rightNum.cyl - leftNum.cyl) >= 3) {
        resolve(false) // 未通过 (提醒)
      }
      resolve(true)
    })
  },
  validatePrescriptionTable({ commit, state }, payload) {
    if (!payload.od_sph && !payload.od_cyl && !payload.os_sph && !payload.os_cyl) {
      return new Promise((resolve, reject) => {
        resolve({
          sph: false,
          cyl: false
        })
      })
    }
    const rightNum = {
      sph: getNum(payload.od_sph),
      cyl: getNum(payload.od_cyl)
    }
    const leftNum = {
      sph: getNum(payload.os_sph),
      cyl: getNum(payload.os_cyl)
    }
    return new Promise((resolve, reject) => {
      resolve({
        sph: getAbs(rightNum.sph - leftNum.sph) >= 3,
        cyl: getAbs(rightNum.cyl - leftNum.cyl) >= 3
      })
    })
  },
  //  进入流程页  获取商品信息
  // async getFlowStart({ commit, state }, payload) {
  //   const data = await payload.axios.product.flowStart({ product_id: payload.id })
  //   return new Promise((resolve, reject) => {
  //     if (data.code === 200) {
  //       const process = data.data.process
  //       commit('setFootData', data.data.product)
  //       // commit('controlStepData', {
  //       //   size: process.step,
  //       //   title: process.title,
  //       //   name: process.type
  //       // })
  //       commit('setFlowData', process.data)
  //     }
  //     resolve(data)
  //   })
  // },
  // 保存流程处方图片
  async saveFlowPrescription({ commit, state }, payload) {
    const data = await payload.axios.product.FlowUploadImg(payload.data, { 'Content-Type': 'multipart/form-data' })
    return new Promise((resolve, reject) => {
      if (data.code === 200) {
        resolve(data)
      } else {
        resolve(data)
      }
    })
  },
  // 获取分步流程信息
  async getFlowInfo({ commit, state }, payload) {
    // 开启loading
    commit('setLoadingState', true)
    // 当前步骤不为最后一步 获取下一步
    const data = await payload.axios.product.FlowNext({
      code: payload.code,
      step_data: state.selectData, // 已选择的流程数据
      step_id: state.step.step_id
    })
    let getState = false
    // 开启loading
    commit('setLoadingState', false)
    if (data.code === 200) {
      getState = true
      const res = data.data
      // 设置流程控制信息
      commit('controlStepData', {
        size: res.count,
        // title: res.title,
        name: res.step,
        glassesType: data.type,
        step_id: data.step_id
        // is_end: res.is_end
      })
      // 设置流程数据
      commit('setFlowData', res.data)
      // 处于编辑状态时(购物车) 不设置第一步流程 将在回退时 control页插入
      if (!payload.edit) {
        // 设置经过流程
        commit('setFlowList', {
          name: res.step,
          title: res.title,
          end: res.is_end !== 0
        })
      }
      // 禁用按钮
      if (res.step === 'prescription') {
        // state.step.nextControl = false
        commit('setFootBtn', false)
      } else {
        commit('setFootBtn', true)
        // state.step.nextControl = true
      }
      // commit('controlStep', 'next')
      // return new Promise((resolve, reject) => {
      //   resolve(res.is_end)
      // })
    } else if (data.code === 0) {
      commit('backControlStep')
    }
    return new Promise((resolve, reject) => {
      resolve(getState)
    })
  },
  // 流程项提交入库======>>>>>进购物车
  async FlowSubmit({ dispatch, state }, payload) {
    const data = await payload.axios.product.FlowSubmit({
      code: payload.code,
      // process_id: payload.process_id,
      step_data: state.selectData,
      step_id: state.step.step_id
    })
    return new Promise((resolve, reject) => {
      resolve(data.code)
    })
    // console.log(data)
    // if (data.code === 200) {

    // }
  }
}
// 获取数字
const getNum = (val) => {
  return parseFloat(val) || 0
}
// 获取绝对值
const getAbs = (val) => {
  return Math.abs(val) || 0
}

export default {
  state,
  getters,
  mutations,
  actions
}
