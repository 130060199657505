import Vue from 'vue'
import myCom from '@/components/global/loading.vue'
const loading = {}
function setBody(type) {
  // 页面滚动
  // true 允许
  const body = document.body

  if (type) {
    body.style.overflow = 'unset'
    body.style.height = 'auto'
  } else {
    body.style.overflow = 'hidden'
    body.style.height = '100vh'
  }
}
loading.install = function(Vue) {
  const Mydialog = Vue.extend(myCom)
  // 生成一个该子类的实例
  const instance = new Mydialog()
  Vue.prototype.$loading = (config) => {
    // instance.config = Object.assign({}, instance.config, config)
    // instance.show = true
    // setBody(false)
    // 这里 return 一个 Promise
    // 在项目中使用的时候，就可以进行链式调用了
    // return new Promise((resolve, reject) => {
    //   //   setBody(false)
    //   // console.log(config)
    //   //   instance.config = Object.assign({}, instance.config, config)
    //   instance.show = true
    //   instance.open = () => {
    //     setBody(false)
    //     resolve()
    //   }
    //   instance.close = () => {
    //     setBody(true)
    //     resolve()
    //   }
    // })
    instance.config = Object.assign({}, instance.config, config)
    return {
      open: () => {
        instance.show = true
        setBody(false)
      },
      close: () => {
        instance.show = false
        setBody(true)
      }
    }
  }
  // 将这个实例挂载在我创建的div上
  // 并将此div加入全局挂载点内部
  instance.$mount(document.createElement('div'))
  document.body.appendChild(instance.$el)
}
Vue.use(loading)
