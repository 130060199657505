import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f55de336 = () => interopDefault(import('..\\pages\\centerMain.vue' /* webpackChunkName: "pages/centerMain" */))
const _2488bd98 = () => interopDefault(import('..\\pages\\centerMain\\account.vue' /* webpackChunkName: "pages/centerMain/account" */))
const _49fb069c = () => interopDefault(import('..\\pages\\centerMain\\order\\after-sales.vue' /* webpackChunkName: "pages/centerMain/order/after-sales" */))
const _353d7fe5 = () => interopDefault(import('..\\pages\\centerMain\\order\\order.vue' /* webpackChunkName: "pages/centerMain/order/order" */))
const _5220b869 = () => interopDefault(import('..\\pages\\centerMain\\order\\order-detail.vue' /* webpackChunkName: "pages/centerMain/order/order-detail" */))
const _425b80b3 = () => interopDefault(import('..\\pages\\centerMain\\order\\template.vue' /* webpackChunkName: "pages/centerMain/order/template" */))
const _99ecbdd8 = () => interopDefault(import('..\\pages\\centerMain\\personalization\\wishlist.vue' /* webpackChunkName: "pages/centerMain/personalization/wishlist" */))
const _0a1ae15c = () => interopDefault(import('..\\pages\\centerMain\\settings\\address.vue' /* webpackChunkName: "pages/centerMain/settings/address" */))
const _3d7c7996 = () => interopDefault(import('..\\pages\\centerMain\\settings\\Info.vue' /* webpackChunkName: "pages/centerMain/settings/Info" */))
const _4ded94e3 = () => interopDefault(import('..\\pages\\centerMain\\settings\\password.vue' /* webpackChunkName: "pages/centerMain/settings/password" */))
const _e2cae23c = () => interopDefault(import('..\\pages\\centerMain\\settings\\prescription.vue' /* webpackChunkName: "pages/centerMain/settings/prescription" */))
const _c96deb36 = () => interopDefault(import('..\\pages\\centerMain\\settings\\subscription.vue' /* webpackChunkName: "pages/centerMain/settings/subscription" */))
const _4ef9ead0 = () => interopDefault(import('..\\pages\\centerMain\\tickets\\my-tickets.vue' /* webpackChunkName: "pages/centerMain/tickets/my-tickets" */))
const _5f9d584c = () => interopDefault(import('..\\pages\\centerMain\\tickets\\tickets-history.vue' /* webpackChunkName: "pages/centerMain/tickets/tickets-history" */))
const _550d5a9f = () => interopDefault(import('..\\pages\\centerMain\\wallet\\coupons.vue' /* webpackChunkName: "pages/centerMain/wallet/coupons" */))
const _047afd52 = () => interopDefault(import('..\\pages\\centerMain\\wallet\\wallet.vue' /* webpackChunkName: "pages/centerMain/wallet/wallet" */))
const _7e9173a2 = () => interopDefault(import('..\\pages\\contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _73b48294 = () => interopDefault(import('..\\pages\\control.vue' /* webpackChunkName: "pages/control" */))
const _1c1e85e8 = () => interopDefault(import('..\\pages\\control\\index.vue' /* webpackChunkName: "pages/control/index" */))
const _5087a40e = () => interopDefault(import('..\\pages\\control\\language.vue' /* webpackChunkName: "pages/control/language" */))
const _10348444 = () => interopDefault(import('..\\pages\\control\\search.vue' /* webpackChunkName: "pages/control/search" */))
const _01b03e5a = () => interopDefault(import('..\\pages\\control\\_type.vue' /* webpackChunkName: "pages/control/_type" */))
const _99875dca = () => interopDefault(import('..\\pages\\details.vue' /* webpackChunkName: "pages/details" */))
const _ad595ffe = () => interopDefault(import('..\\pages\\details\\_code.vue' /* webpackChunkName: "pages/details/_code" */))
const _5edbe242 = () => interopDefault(import('..\\pages\\payment.vue' /* webpackChunkName: "pages/payment" */))
const _d1cac67a = () => interopDefault(import('..\\pages\\payment\\index.vue' /* webpackChunkName: "pages/payment/index" */))
const _54933862 = () => interopDefault(import('..\\pages\\payment\\index copy.vue' /* webpackChunkName: "pages/payment/index copy" */))
const _60e498f4 = () => interopDefault(import('..\\pages\\payment\\index copy 2.vue' /* webpackChunkName: "pages/payment/index copy 2" */))
const _2a2b1fd6 = () => interopDefault(import('..\\pages\\payment-success.vue' /* webpackChunkName: "pages/payment-success" */))
const _590be570 = () => interopDefault(import('..\\pages\\product.vue' /* webpackChunkName: "pages/product" */))
const _4f97738c = () => interopDefault(import('..\\pages\\product\\index.vue' /* webpackChunkName: "pages/product/index" */))
const _30ed8a42 = () => interopDefault(import('..\\pages\\search.vue' /* webpackChunkName: "pages/search" */))
const _026e2561 = () => interopDefault(import('..\\pages\\search\\accessories.vue' /* webpackChunkName: "pages/search/accessories" */))
const _659dd6ec = () => interopDefault(import('..\\pages\\search\\_type.vue' /* webpackChunkName: "pages/search/_type" */))
const _2c228e9f = () => interopDefault(import('..\\pages\\shoppingCart.vue' /* webpackChunkName: "pages/shoppingCart" */))
const _90a235fa = () => interopDefault(import('..\\pages\\shoppingCart\\index.vue' /* webpackChunkName: "pages/shoppingCart/index" */))
const _73ef8afe = () => interopDefault(import('..\\pages\\why-choose-stylool.vue' /* webpackChunkName: "pages/why-choose-stylool" */))
const _a14dc82a = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _28490a37 = () => interopDefault(import('..\\pages\\index\\index.vue' /* webpackChunkName: "pages/index/index" */))
const _105d6ed9 = () => interopDefault(import('..\\pages\\index\\about-us\\index.vue' /* webpackChunkName: "pages/index/about-us/index" */))
const _3c4fe1b1 = () => interopDefault(import('..\\pages\\index\\faq\\_articles.vue' /* webpackChunkName: "pages/index/faq/_articles" */))
const _86df809c = () => interopDefault(import('..\\pages\\_type.vue' /* webpackChunkName: "pages/_type" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/centerMain",
    component: _f55de336,
    name: "centerMain",
    children: [{
      path: "account",
      component: _2488bd98,
      name: "centerMain-account"
    }, {
      path: "order/after-sales",
      component: _49fb069c,
      name: "centerMain-order-after-sales"
    }, {
      path: "order/order",
      component: _353d7fe5,
      name: "centerMain-order-order"
    }, {
      path: "order/order-detail",
      component: _5220b869,
      name: "centerMain-order-order-detail"
    }, {
      path: "order/template",
      component: _425b80b3,
      name: "centerMain-order-template"
    }, {
      path: "personalization/wishlist",
      component: _99ecbdd8,
      name: "centerMain-personalization-wishlist"
    }, {
      path: "settings/address",
      component: _0a1ae15c,
      name: "centerMain-settings-address"
    }, {
      path: "settings/Info",
      component: _3d7c7996,
      name: "centerMain-settings-Info"
    }, {
      path: "settings/password",
      component: _4ded94e3,
      name: "centerMain-settings-password"
    }, {
      path: "settings/prescription",
      component: _e2cae23c,
      name: "centerMain-settings-prescription"
    }, {
      path: "settings/subscription",
      component: _c96deb36,
      name: "centerMain-settings-subscription"
    }, {
      path: "tickets/my-tickets",
      component: _4ef9ead0,
      name: "centerMain-tickets-my-tickets"
    }, {
      path: "tickets/tickets-history",
      component: _5f9d584c,
      name: "centerMain-tickets-tickets-history"
    }, {
      path: "wallet/coupons",
      component: _550d5a9f,
      name: "centerMain-wallet-coupons"
    }, {
      path: "wallet/wallet",
      component: _047afd52,
      name: "centerMain-wallet-wallet"
    }]
  }, {
    path: "/contact-us",
    component: _7e9173a2,
    name: "contact-us"
  }, {
    path: "/control",
    component: _73b48294,
    children: [{
      path: "",
      component: _1c1e85e8,
      name: "control"
    }, {
      path: "language",
      component: _5087a40e,
      name: "control-language"
    }, {
      path: "search",
      component: _10348444,
      name: "control-search"
    }, {
      path: ":type",
      component: _01b03e5a,
      name: "control-type"
    }]
  }, {
    path: "/details",
    component: _99875dca,
    name: "details",
    children: [{
      path: ":code?",
      component: _ad595ffe,
      name: "details-code"
    }]
  }, {
    path: "/payment",
    component: _5edbe242,
    children: [{
      path: "",
      component: _d1cac67a,
      name: "payment"
    }, {
      path: "index%20copy",
      component: _54933862,
      name: "payment-index copy"
    }, {
      path: "index%20copy%202",
      component: _60e498f4,
      name: "payment-index copy 2"
    }]
  }, {
    path: "/payment-success",
    component: _2a2b1fd6,
    name: "payment-success"
  }, {
    path: "/product",
    component: _590be570,
    children: [{
      path: "",
      component: _4f97738c,
      name: "product"
    }]
  }, {
    path: "/search",
    component: _30ed8a42,
    name: "search",
    children: [{
      path: "accessories",
      component: _026e2561,
      name: "search-accessories"
    }, {
      path: ":type?",
      component: _659dd6ec,
      name: "search-type"
    }]
  }, {
    path: "/shoppingCart",
    component: _2c228e9f,
    children: [{
      path: "",
      component: _90a235fa,
      name: "shoppingCart"
    }]
  }, {
    path: "/why-choose-stylool",
    component: _73ef8afe,
    name: "why-choose-stylool"
  }, {
    path: "/",
    component: _a14dc82a,
    children: [{
      path: "",
      component: _28490a37,
      name: "index"
    }, {
      path: "about-us",
      component: _105d6ed9,
      name: "index-about-us"
    }, {
      path: "faq/:articles?",
      component: _3c4fe1b1,
      name: "index-faq-articles"
    }]
  }, {
    path: "/:type",
    component: _86df809c,
    name: "type"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
