import { multiply } from '@/assets/utils/decimal'
import { getRandomString } from '@/assets/utils/toolFn'
import { setLocalStorage, getLocalStorage } from '@/assets/utils/storage'
const rates = {
  USD: 1,
  AUD: 1.4676,
  EUR: 0.9342,
  CAD: 1.3551,
  GBP: 0.8266
}
// 持久化保存  插件localStorage内设置
const state = () => ({
  from: 'index', // 跳转前记录
  // try---------------
  tryOnShow: false,
  tryOnImg: undefined,
  tryCode: undefined,
  // 跳转参数----------
  queryCurrent: {},
  paramsCurrent: {},
  couponsDialog: false, // 优惠弹窗
  overlay: false, // 阴影
  navDialog: false, // 导航弹窗
  loginDialogTab: 0, // 登录弹窗默认tab
  loginDialog: false, // 登录弹窗
  resetDialog: false, // 重置密码
  requestLoading: false, // 请求loading
  currencyIndx: 0, // 当前货币
  currencyList: [ // 货币列表
    { value: 'USD', icon: 'usd.webp' }
    // { value: 'AUD', icon: 'aud.webp' },
    // { value: 'EUR', icon: 'eur.webp' },
    // { value: 'CAD', icon: 'cad.webp' },
    // { value: 'GBP', icon: 'gbp.webp' }
  ],
  // 导航菜单
  menuList: ['Eyeglasses', 'Sunglasses', 'New Arrivals', 'Flash Sale', 'Best Sellers', 'Accessories'],
  // 用户token
  // userToken: getToken('Token'),
  userToken: undefined,
  userInfo: {},
  CollectionsAndCartsNum: {}
})
const getters = {
  // currency: state => state.currencyList[state.currencyIndx].title,
  // 货币转换 基准(美元)
  currency: state => {
    const current = state.currencyList[state.currencyIndx].value
    // return multiply(val, rates[current])
    return (val) => {
      return multiply(val, rates[current])
    }
  },
  currencyIcon: state => {
    let mark
    switch (state.currencyList[state.currencyIndx].value) {
      case 'USD':
        mark = '$'
        break
      case 'GBP':
        mark = '￡'
        break
      case 'CAD':
        mark = 'C$'
        break
      case 'AUD':
        mark = 'A$'
        break
      case 'EUR':
        mark = '€'
        break
      default:
        mark = '$'
        break
    }
    return mark
  }
}
const mutations = {
  // 登录弹窗tab
  loginDialogTab(state, payload) {
    state.loginDialogTab = payload
  },
  // 登录弹窗
  toggleLogin(state, payload) {
    state.loginDialog = payload
  },
  toggleReset(state, payload) {
    state.resetDialog = payload
  },
  // 打开nav前的记录
  saveGoFrom(state, payload) {
    // console.log(payload)
    state.from = payload.name
    state.queryCurrent = payload.query
    state.paramsCurrent = payload.params
  },
  // 全局阴影控制
  toggleOverlay(state, payload) {
    state.overlay = payload
  },
  // 优惠券弹窗
  toogglecoupons(state, payload) {
    state.couponsDialog = payload
  },
  toggleNavLog(state, payload) {
    state.navDialog = payload
  },
  // tryOn控制
  toggleTryOnShow(state, payload) {
    state.tryOnShow = payload.state
    state.tryOnImg = payload.img
    state.tryCode = payload.code
  },
  // 全局 loading控制
  toggleLoading(state, payload) {
    state.requestLoading = payload
  },
  // 切换货币
  toggleCurrency(state, payload) {
    state.currencyIndx = payload
  },
  setcks(state, payload) {
    state.cks = payload
  },
  // 设置货币列表
  setCountry(state, payload) {
    state.currencyList = payload
  },
  // 保存登录Token
  saveToken(state, payload) {
    state.userToken = payload
  },
  // logOut  退出登录
  logOut(state) {
    state.userToken = undefined
    // sessionStorage.getItem('COUPON_TAG')
    try {
      sessionStorage.removeItem('COUPON_TAG')
    } catch (error) {

    }
    state.userInfo = {}
    setLoaclUser()
    // console.log(window.$next)
    window.$nuxt.$router.go(0)
  },
  setUserInfo(state, payload) {
    state.userInfo = payload
    recordPageEnter('bearer ' + state.userToken)
    // 登录时启用记录监听
    recordPage('bearer ' + state.userToken)
  },
  // 设置导航栏 购物车和收藏数量
  setNavNum(state, payload) {
    state.CollectionsAndCartsNum = payload
  }
}
function recordPageEnter(userToken) {
  const url = '/api/user/joinUserTime'
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken
    },
    keepalive: true
  })
}
function recordPage(userToken) {
  // 单页面
  document.addEventListener(
    'visibilitychange',
    function () {
      // 用户离开了当前页面
      if (document.visibilityState === 'hidden') {
        const url = '/api/user/leaveUserTime'
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: userToken
          },
          keepalive: true
        })
      }
    }
  )
}
const actions = {
  async getCountry({ commit, state }, payload) {
    const res = await payload.axios.system.getCountry()
    if (res.code === 200) {
      commit('setCountry', res.data)
    }
    // return new Promise((resolve, reject) => {
    //    if (res.code === 200) {
    //      commit('setCountry', res.data)
    //   }
    //   // resolve(res)
    // })
  },
  async getUserInfo({ commit, state }, payload) {
    if (state.userToken) {
      const res = await payload.axios.userCenter.userInfo()
      if (res.code === 200) {
        commit('setUserInfo', res.data)
      }
    } else {
      // 生成本地用户id
      setLoaclUser()
    }
  },
  async getCollectionsAndCartsNum({ commit, state }, payload) {
    const [data1, data2] = await Promise.all([payload.axios.system.getWishlistCount(), payload.axios.system.getCartCount()])
    const num = {
      collect: 0,
      cart: 0
    }
    if (data1.code === 200) {
      num.collect = data1.data
    }
    if (data2.code === 200) {
      num.cart = data2.data
    }
    commit('setNavNum', num)
  }
}
// 创建本地用户
function setLoaclUser() {
  const key = 'LOACL_DD'
  const id = getLocalStorage(key)
  if (!id) {
    setLocalStorage(key, getRandomString(68))
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
