export default $axios => ({
  // 问题搜索
  getQuestion(data) {
    return $axios({
      url: '/api/question/search',
      method: 'post',
      data
    })
  },
  // 获取问题列表
  getFaqList(data) {
    return $axios({
      url: '/api/question/list',
      method: 'post',
      data
    })
  },
  // 轮播图
  getBanner() {
    return $axios({
      url: '/api/other/banner',
      method: 'get'
    })
  },
  // 获取Faq详情
  getFaqInfo(data) {
    return $axios({
      url: '/api/question/info',
      method: 'post',
      data
    })
  },
  // 获取其他文案详情
  getCopywritingInfo(data) {
    return $axios({
      url: '/api/question/other',
      method: 'post',
      data
    })
  },
  // 搜索 --- 热搜词
  getHotTag() {
    return $axios({
      url: '/api/other/hotSearchWords',
      method: 'get'
    })
  },
  // 照片墙
  getPhotoWall() {
    return $axios({
      url: '/api/other/photoWall',
      method: 'get'
    })
  },
  // 照片墙详情
  getPhotoInfo(data) {
    return $axios({
      url: '/api/other/photoWallInfo',
      method: 'post',
      data
    })
  }
})
