// import { setLocalStorage, getLocalStorage, removeLocalStorage } from '@/assets/utils/storage'
export const goodsData = list => {
  const data = []
  if (Object.prototype.toString.call(list) !== '[object Array]' || !list.length) {
    return []
  }
  for (let index = 0; index < list.length; index++) {
    const top = list[index]
    const currentData = {
      id: index, // 模拟id
      ...top[0], // 默认渲染第一项
      theme: [],
      themeList: []
    }
    for (let index2 = 0; index2 < top.length; index2++) {
      const element = top[index2]
      // 主题列表
      const item = {}
      item.id = element.id
      item.color = element.color
      item.themeImg = element.themeImg
      currentData.theme.push(item)
      // 商品列表
      currentData.themeList.push(element)
    }
    data.push(currentData)
  }
  // console.log(data)
  return data
}
export const typeName = val => {
  let str = ''
  switch (val) {
    case 'eyeglasses':
      str = 'Eyeglasses'
      break
    case 'sunglasses':
      str = 'Sunglasses'
      break
    case 'new_arrivals':
      str = 'New Arrivals'
      break
    case 'flash_sale':
      str = 'Flash Sale'
      break
    case 'best_sellers':
      str = 'Best Sellers'
      break
    case 'accessories':
      str = 'Accessories'
      break
    // ------------------------------------
    case 'Eyeglasses':
      str = 'eyeglasses'
      break
    case 'Sunglasses':
      str = 'sunglasses'
      break
    case 'New Arrivals':
      str = 'new-arrivals'
      break
    case 'Flash Sale':
      str = 'flash-sale'
      break
    case 'Best Sellers':
      str = 'best-sellers'
      break
    case 'Accessories':
      str = 'accessories'
      break
    // ------------------------------------
    default:
      str = ''
      break
  }
  return str
}
// 根据属性名重命名name  (处方配置)
export const getItem = setpName => {
  if (!setpName) {
    return ''
  }
  let name
  switch (setpName) {
    case 'lens_use':
      name = 'Prescription Type'
      break
    case 'prescription':
      name = 'config'
      break
    case 'magnification':
      name = 'Magnification'
      break
    case 'add_ones':
      name = 'Additional Price'
      break
    case 'lens_index':
      name = 'Lens Index'
      break
    case 'lens_type':
      name = 'Lens Type'
      break
    default:
      name = 'lens'
      break
  }
  return name
}
// 根据状态码显示状态
export const renderStatus = num => {
  let status = ''
  switch (num) {
    case 0:
      status = 'Unpaid'
      break
    case 1:
      status = 'Not Shipped'
      break
    case 2:
      status = 'Shipped'
      break
    case 3:
      status = 'Pending Review'
      break
    case 4:
      status = 'Completed'
      break
    case 5:
      status = 'Refunded'
      break
    case 6:
      status = 'Expired'
      break
    default:
      status = ''
      break
  }
  return status
}
// 创建本地购物车数据
// export const setLoaclCart = (type, data) => {
//   const info = {
//     code: data.code,
//     color: data.color,
//     price: data.price,
//     price_total: data.price_total,
//     process: [],
//     stock: data.stock,
//     num: 1
//   }
// }
export const replaceSunglasses = (str) => {
  if (str) {
    const list = ['Prescription Tinted Sunglasses', 'Prescription Polarized Sunglasses', 'Non-Prescription Tinted Sunglasses', 'Non-Prescription Polarized Sunglasses']
    if (list.includes(str)) {
      return str.replace('Sunglasses', 'Grey')
    }
    return str
  }
  return str
}
// 对象转 FormData
export const getFormData = (object) => {
  const formData = new FormData()
  Object.keys(object).forEach(key => {
    const value = object[key]
    if (Array.isArray(value)) {
      value.forEach((subValue, i) => {
        formData.append(key + `[${i}]`, subValue)
      })
    } else {
      formData.append(key, object[key] === null ? '' : object[key])
    }
  })
  return formData
}
