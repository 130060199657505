
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

const cookieStorage = {
  getItem: function (key) {
    return Cookies.getJSON(key)
  },
  // expires 过期时间 (天)
  // secure 只在https 传输
  setItem: function (key, value) {
    return Cookies.set(key, value, { expires: 2, secure: false })
  },
  removeItem: function(key) {
    return Cookies.remove(key)
  }
}
// system 持久化保存的数据
const systemData = ['system.currencyIndx', 'system.currencyList', 'system.userToken', 'system.userInfo', 'system.CollectionsAndCartsNum']
const saveData = [...systemData]
export default (context) => {
  createPersistedState({
    paths: saveData,
    storage: cookieStorage,
    getState: cookieStorage.getItem,
    setState: cookieStorage.setItem
  })(context.store)
}
