import { getLocalStorage } from '@/assets/utils/storage'
const key = 'LOACL_DD'
export default $axios => ({
  // 添加购物车
  addToCart(data, headers = {}) {
    headers.localdd = getLocalStorage(key)
    return $axios({
      url: '/api/cart/add',
      method: 'post',
      data,
      headers
    })
  },
  // 购物车列表
  cartList(headers = {}) {
    headers.localdd = getLocalStorage(key)
    return $axios({
      url: '/api/cart/list',
      method: 'post',
      headers
    })
  },
  delProduct(data, headers = {}) {
    headers.localdd = getLocalStorage(key)
    return $axios({
      url: '/api/cart/delete',
      method: 'post',
      data,
      headers
    })
  },
  editNum(data, headers = {}) {
    headers.localdd = getLocalStorage(key)
    return $axios({
      url: '/api/cart/num',
      method: 'post',
      data,
      headers
    })
  },
  // 商品列表
  payProductList(data, header = {}) {
    return $axios({
      url: '/api/order/productList',
      method: 'post',
      data,
      header
    })
  },
  // 商品列表
  paySubmit(data, header = {}) {
    return $axios({
      url: '/api/order/submit',
      method: 'post',
      data,
      header
    })
  },
  // 添加购物车未完成(镜框)
  incompleteFrame(data, header = {}) {
    return $axios({
      url: '/api/order/incompleteFrame',
      method: 'post',
      data,
      header
    })
  },
  // 购物车快照
  cartSnapshot(data, header = {}) {
    return $axios({
      url: '/api/cart/snapshoot',
      method: 'post',
      data,
      header
    })
  },
  // 地址快照
  snapshootShipping(data, header = {}) {
    return $axios({
      url: '/api/cart/snapshootShipping',
      method: 'post',
      data,
      header
    })
  },
  // 支付快照
  snapshootStatus(data, header = {}) {
    return $axios({
      url: '/api/cart/snapshootStatus',
      method: 'post',
      data,
      header
    })
  },
  // 获取获取快递费用/方式
  payDelivery(data, header = {}) {
    return $axios({
      url: '/api/order/expressFeeList',
      method: 'post',
      data,
      header
    })
  },
  // 提交订单
  orderSubmit(data, header = {}) {
    return $axios({
      url: '/api/order/submit',
      method: 'post',
      data,
      header
    })
  },
  // paypal 创建订单
  createOrder(data, headers = {}) {
    return $axios({
      url: '/api/paypal/createOrder',
      method: 'post',
      data,
      headers
    })
  },
  // paypal 创建订单
  createOrderStripe(data, headers = {}) {
    return $axios({
      url: '/api/stripe/createOrder',
      method: 'post',
      data,
      headers
    })
  },
  // paypal 创建订单
  capturePayment(data, headers = {}) {
    return $axios({
      url: '/api/paypal/capturePayment',
      method: 'post',
      data,
      headers
    })
  }
})
