/**
* 检查字符串是否为合法手机号码
* @param {String} 字符串
* @return {bool} 是否为合法手机号码
*/
const isPhone = aPhone => {
  const bValidate = (/^(0|86|17951)?(13[0-9]|15[012356789]|18[0-9]|14[57])[0-9]{8}$/).test(aPhone)
  if (bValidate) {
    return true
  } else { return false }
}
/**
* 检查字符串是否为26个英文字母组成
* @param {String} 字符串
* @return {bool} 是否为26个英文字母组成
*/
const isName = str => {
  const bValidate = (/^[A-Za-z]+$/).test(str)
  if (bValidate) {
    return true
  } else { return false }
}
/**
* 必须为字母加数字且长度不小于6位
* @param {String} 字符串
* @return {bool} 是否为英文和数字
*/
const isPassWord = str => {
  if (str == null || str.length < 6) {
    return false
  }
  const bValidate1 = (/^[0-9A-Za-z]+$/).test(str)
  if (!bValidate1) {
    return false
  }
  const bValidate2 = (/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/).test(str)
  if (bValidate2) {
    return true
  } else {
    return false
  }
}
/**
* 必须长度不小于8位不包含汉字
* @param {String} 字符串
* @return {bool} 是否为英文和数字
*/
const isPassWord2 = password => {
  const pattern = /^[^\u4E00-\u9FA5]{8,}$/
  return pattern.test(password)
}
/**
* 检查字符串是否为合法email地址
* @param {String} 字符串
* @return {bool} 是否为合法email地址
*/
const isEmail = (aEmail) => {
  // eslint-disable-next-line no-useless-escape
  const bValidate = (/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/).test(aEmail)
  if (bValidate) {
    return true
  } else { return false }
}

/**
* 检查字符串是否是整数
* @param {String} 字符串
* @return {bool} 是否是整数
*/
const isInteger = (s) => {
  const isInteger = (/^[0-9]+$/)
  return (isInteger.test(s))
}
/*
判断字符类型
*/
const CharMode = (iN) => {
  // 数字
  if (iN >= 48 && iN <= 57) { return 1 }
  // 大写字母
  if (iN >= 65 && iN <= 90) { return 2 }
  // 小写
  if (iN >= 97 && iN <= 122) { return 4 } else { return 8 } // 特殊字符
}
/*
统计字符类型
*/
const bitTotal = (num) => {
  let modes = 0
  for (let i = 0; i < 4; i++) {
    if (num & 1) { modes++ }
    num >>>= 1
  }
  return modes
}
/*
返回密码的强度级别
*/
const checkStrong = (sPW) => {
  if (sPW.length <= 4) { return 0 } // 密码太短
  let Modes = 0
  for (let i = 0; i < sPW.length; i++) {
    // 测试每一个字符的类别并统计一共有多少种模式.
    Modes |= CharMode(sPW.charCodeAt(i))
  }
  return bitTotal(Modes)
}
/**
* 检查字符串是否是日期格式  MM-DD-YY
* @param {String} 日期字符串
*/
const checkDate = (dateStr) => {
  const regex = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/
  return regex.test(dateStr)
}
/**
* 检查字符串是否是四位数验证码
* @param {String} 日期字符串
*/
const validateCode = (code) => {
  const regExp = /^[a-zA-Z0-9]{4}$/
  return regExp.test(code)
}
/**
* 检查字符串是否为美国格式
* @param {String} 日期字符串
*/
const ChecktelUsa = (str) => {
  const a = /^[2-9]\d{2}-\d{3}-\d{4}$/
  if (!a.test(str)) {
    return false
  }
  return true
}
/**
* 检查字符串是否为意大利格式
* @param {String} 日期字符串
*/
const ChecktelCa = (str) => {
  const a = /^[2-9]\d{2}-\d{3}-\d{4}$/
  if (!a.test(str)) {
    return false
  }
  return true
}
export {
  isPhone,
  isName,
  isPassWord,
  isPassWord2,
  validateCode,
  isEmail,
  isInteger,
  CharMode,
  bitTotal,
  checkStrong,
  checkDate,
  ChecktelUsa,
  ChecktelCa
}
