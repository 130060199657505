import { Decimal } from 'decimal.js'
/**
 * 加法
 * @param arg1
 * @param arg2
 * @returns {number}
 */
export const add = (arg1, arg2) => {
  return Decimal.add(arg1, arg2).toNumber()
}
/**
 * 减法
 * @param arg1
 * @param arg2
 * @returns {number}
 */
export const subtract = (arg1, arg2) => {
  return Decimal.sub(arg1, arg2).toNumber()
}

/**
 *  * 乘法
 * @param arg1
 * @param arg2
 * @returns {number}
 */
export const multiply = (arg1, arg2) => {
  return Decimal.mul(arg1, arg2).toNumber()
}
/**
 * 除法
 * @param arg1
 * @param arg2
 * @returns {number}
 */
export const divide = (arg1, arg2) => {
  return Decimal.div(arg1, arg2).toNumber()
}
// 强制保留两位小数  位数不够00替补
// function toDecimal2(x) {
//   const f = parseFloat(x)
//   if (isNaN(f)) {
//     return false
//   }
//   const h = Math.round(x * 100) / 100
//   let s = h.toString()
//   let rs = s.indexOf('.')
//   if (rs < 0) {
//     rs = s.length
//     s += '.'
//   }
//   while (s.length <= rs + 2) {
//     s += '0'
//   }
//   return s
// }
